export default {
  "row": "cNz body-1",
  "active": "cNa",
  "cell": "cNH",
  "empty": "cNO",
  "ellipsis": "cNm",
  "text-secondary": "cNp",
  "transfer-type": "cNx",
  "transfer-type__icon": "cNw",
  "quick-actions": "cNN",
  "amount": "cNv",
  "cell-approve": "cNo",
  "account-select": "cNn",
  "row-compact": "cNi",
  "cell-amount": "cNA",
  "cell-content-receipt": "cNY body-1",
  "cell-date": "cNh",
  "row-sidebar": "cNf",
  "hidden": "cNK",
  "animated": "cNG",
  "fadein-item": "cNr",
  "animated-cell": "cNb",
  "note": "cNy",
  "checkbox": "cNU"
};
