export default {
  "header": "chH",
  "header-type": "chO",
  "close": "chm",
  "close-icon": "chp",
  "header-infos": "chx",
  "title": "chw body-1",
  "declined": "chN",
  "canceled": "chv",
  "approved": "cho",
  "pending": "chn",
  "date": "chi body-2",
  "mileage-icon": "chA",
  "distance-subtitle": "chY body-2",
  "link-icon": "chh",
  "container": "chf",
  "picto": "chK",
  "general": "chG",
  "amount": "chr",
  "calculation-container": "chb",
  "counterparty": "chy body-2",
  "infobox": "chU"
};
