export default {
  "header": "cvF",
  "header-type": "cvg",
  "close": "cvD",
  "close-icon": "cvV",
  "header-infos": "cvL",
  "title": "cvT body-1",
  "declined": "cvz",
  "canceled": "cva",
  "approved": "cvH",
  "pending": "cvO",
  "date": "cvm body-2",
  "container": "cvp",
  "picto": "cvx",
  "picto-status": "cvw",
  "general": "cvN",
  "amount": "cvv",
  "counterparty": "cvo body-2",
  "initiator": "cvn",
  "initiator-avatar": "cvi",
  "avatar-image": "cvA",
  "initiator-id": "cvY",
  "name": "cvh body-2",
  "role": "cvf caption-bold",
  "infobox": "cvK",
  "account-infobox": "cvG cvK",
  "beneficiary-warning": "cvr caption-bold",
  "dates-wrapper": "cvb",
  "dates": "cvy",
  "recurrence": "cvU caption-bold",
  "arrow": "cvj",
  "notify-checkbox": "cvW"
};
