export default {
  "mileage-validation": "cNd",
  "actions": "cNI",
  "action-btn": "cNt",
  "action-btn-success": "cNM",
  "action-btn-right": "cNP",
  "request-mileage-validation": "cNl",
  "account-selector": "cNX",
  "has-error": "cNC",
  "funds-disclaimer": "cNk",
  "account-options": "cNJ",
  "account-balance": "cNs",
  "warnings": "cNF"
};
