/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Tag } from '@repo/design-system-kit';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

// @ts-expect-error
import { QUOTE_STATUS_COLORS } from 'qonto/constants/quotes';
import { INVOICED_STATUS, INVOICING_STATUS_COLORS } from 'qonto/constants/receivable-invoice';

interface ReceivableInvoicesTableItemSignature {
  // The arguments accepted by the component
  Args: {
    isQuote?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class ReceivableInvoicesTableItem extends Component<ReceivableInvoicesTableItemSignature> {
  tag = Tag;

  @service declare intl: Services['intl'];

  get status() {
    // @ts-expect-error
    let { status, displayedStatus } = this.args.item;
    let statusColors = this.args.isQuote ? QUOTE_STATUS_COLORS : INVOICING_STATUS_COLORS;

    return {
      displayedStatus,
      color: statusColors[status],
    };
  }

  get amountValue() {
    // @ts-expect-error
    let { amountDue } = this.args.item;
    return amountDue
      ? // @ts-expect-error
        this.intl.formatMoney(amountDue, { currency: this.currency })
      : this.intl.t('receivable-invoices.label.missing-data');
  }

  get currency() {
    // @ts-expect-error
    return this.args.item.currency || 'EUR';
  }

  get isInvoicedStatusDisplayed() {
    // @ts-expect-error
    let { invoicedStatus } = this.args.item;
    return (
      this.args.isQuote &&
      [
        INVOICED_STATUS.FULLY_INVOICED,
        INVOICED_STATUS.PARTIALLY_INVOICED,
        INVOICED_STATUS.CURRENCY_MISMATCH,
      ].includes(invoicedStatus)
    );
  }

  get invoicedStatusTooltip() {
    // @ts-expect-error
    let { invoicedStatus } = this.args.item;
    if (invoicedStatus === INVOICED_STATUS.FULLY_INVOICED) {
      return this.intl.t('receivable-invoices.quotes.fully-invoiced.tooltip');
    } else if (
      invoicedStatus === INVOICED_STATUS.PARTIALLY_INVOICED ||
      invoicedStatus === INVOICED_STATUS.CURRENCY_MISMATCH
    ) {
      return this.intl.t('receivable-invoices.quotes.partially-invoiced.tooltip');
    }
  }

  get dueDateOrExpiryDate() {
    // @ts-expect-error
    return this.args.item.dueDate || this.args.item.expiryDate;
  }

  get isNumberingModeAutomatic() {
    // @ts-expect-error
    let { settings } = this.args;
    return settings?.numberingMode === 'automatic';
  }

  get isNumberDisplayed() {
    // @ts-expect-error
    let { item } = this.args;
    return this.isRecurringInvoice || !(item.status === 'draft' && this.isNumberingModeAutomatic);
  }

  get isRecurringInvoice() {
    // @ts-expect-error
    let { item } = this.args;
    return (
      Boolean(item.invoiceSubscription) &&
      variation('feature--boolean-ar-document-templates-slice-1')
    );
  }

  get creditNoteTooltipMessage() {
    // @ts-expect-error
    let { item } = this.args;
    return this.intl.t('receivable-invoices.invoices-list.credit-note-icon.tooltip', {
      creditNotesNumber: item.hasMany('receivableCreditNotes').ids().length,
    });
  }

  get isCreditNoteIconDisplayed() {
    if (this.args.isQuote) {
      return null;
    }
    // @ts-expect-error
    let { item } = this.args;
    return item.hasMany('receivableCreditNotes').ids().length > 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Table::Item': typeof ReceivableInvoicesTableItem;
  }
}
