export default {
  "container": "czO",
  "depositAmount": "czm",
  "flexRow": "czp",
  "depositInput": "czx",
  "dropdown": "czw",
  "itemRow": "czN",
  "line": "czv",
  "estimates-container": "czo",
  "estimates": "czn",
  "dt": "czi",
  "dd": "czA"
};
