export default {
  "wrapper": "crx",
  "beneficiary": "crw",
  "name": "crN body-1",
  "beneficiary-email": "crv body-2",
  "dropdown-icon": "cro",
  "trusted": "crn",
  "account-number": "cri flex body-2",
  "account-icon": "crA",
  "m-chip": "crY",
  "dropdown": "crh body-2",
  "delete-option": "crf",
  "beneficiary-name": "crK"
};
