export default {
  "gap-32": "czc",
  "fieldset": "czq",
  "date-picker": "czZ",
  "with-performance-date": "czR",
  "mt-4": "czQ",
  "clients": "cze",
  "dropdown-option": "czB",
  "dropdown-content": "czE",
  "select-client": "czd",
  "client-option": "czI",
  "icon-tooltip": "czt",
  "payment-details": "czM",
  "dates-container": "czP",
  "automatic-number-label": "czl",
  "label": "czX",
  "numbering-container": "czC",
  "fix-width": "czk",
  "customer-error": "czJ",
  "amount-text": "czs",
  "header-text-field-container": "czF",
  "header-text-field": "czg",
  "header-text-field-close-button": "czD",
  "mt-16": "czV"
};
