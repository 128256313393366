export default {
  "header": "cYL",
  "header-type": "cYT",
  "close": "cYz",
  "close-icon": "cYa",
  "header-infos": "cYH",
  "title": "cYO body-1",
  "declined": "cYm",
  "canceled": "cYp",
  "approved": "cYx",
  "pending": "cYw",
  "date": "cYN body-2",
  "container": "cYv",
  "picto": "cYo",
  "picto-status": "cYn",
  "general": "cYi",
  "amount": "cYA",
  "counterparty": "cYY body-2",
  "initiator": "cYh",
  "initiator-avatar": "cYf",
  "avatar-image": "cYK",
  "initiator-id": "cYG",
  "name": "cYr body-2",
  "role": "cYb caption-bold",
  "infobox": "cYy",
  "account-infobox": "cYU cYy",
  "beneficiary-warning": "cYj caption-bold",
  "dates-wrapper": "cYW",
  "dates": "cYu",
  "recurrence": "chS caption-bold",
  "arrow": "chc",
  "notify-checkbox": "chq"
};
