export default {
  "wrapper": "cpK",
  "tabs": "cpG",
  "tabs-nav": "cpr",
  "tabs-panel": "cpb",
  "tabs-panel-wrapper": "cpy",
  "list-head": "cpU",
  "list": "cpj",
  "footer": "cpW",
  "reward-amount": "cpu",
  "rewarded": "cxS",
  "registered": "cxc",
  "disclaimer": "cxq",
  "empty-state-wrapper": "cxZ",
  "empty-state": "cxR",
  "illustration": "cxQ",
  "spinner": "cxe",
  "total-earned": "cxB"
};
