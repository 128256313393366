export default {
  "container": "cOM",
  "form-container": "cOP",
  "content": "cOl",
  "fields": "cOX",
  "input-settings": "cOC",
  "current-year": "cOk",
  "next-invoice-number": "cOJ",
  "preview-container": "cOs",
  "actions": "cOF",
  "close-button": "cOg btn btn--icon-only btn--tertiary btn--large",
  "preview-image-container": "cOD",
  "preview-image": "cOV"
};
