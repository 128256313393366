export default {
  "content": "ciV",
  "balance": "ciL",
  "duration": "ciT",
  "green-text": "ciz",
  "balance-subtitle": "cia",
  "balance-duration": "ciH",
  "footer": "ciO",
  "progress": "cim",
  "progress-bar": "cip",
  "progress-bar-text": "cix",
  "progress-active": "ciw"
};
