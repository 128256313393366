export default {
  "row": "cyt",
  "row-active": "cyM",
  "avatar": "cyP",
  "cell": "cyl",
  "cell-content": "cyX",
  "cell-counterparty": "cyC",
  "counterparty-name": "cyk",
  "cell-amount": "cyJ",
  "status-avatar": "cys",
  "counterparty": "cyF",
  "creditor-name": "cyg",
  "reference": "cyD",
  "counterparty-details": "cyV",
  "cell-quick-actions": "cyL",
  "quick-actions": "cyT",
  "account-selector": "cyz"
};
