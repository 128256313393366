export default {
  "attachment-viewer": "cvR",
  "hide-sidebar": "cvQ",
  "sidebar": "cve",
  "header": "cvB",
  "header-top": "cvE",
  "header-icon": "cvd",
  "header-amount": "cvI",
  "infobox": "cvt",
  "vat": "cvM",
  "validation": "cvP"
};
