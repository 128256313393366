export default {
  "item": "cml",
  "details": "cmX",
  "flex": "cmC",
  "header": "cmk cmC",
  "flex-no-gap": "cmJ cmC",
  "file-name": "cms",
  "label": "cmF body-2",
  "self-icon": "cmg",
  "with-separator": "cmD",
  "dimmed": "cmV"
};
