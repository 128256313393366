export default {
  "section-container": "chk",
  "disclaimer-section": "chJ",
  "trip-title": "chs caption-bold mb-16",
  "google-link-container": "chF",
  "link-icon": "chg",
  "trip-details": "chD body-2",
  "trip-details-icon": "chV",
  "trip-details-title": "chL",
  "subtitle": "chT caption-bold mb-16",
  "disclaimer": "chz",
  "visualizer": "cha"
};
