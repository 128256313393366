export default {
  "row": "cya",
  "cell": "cyH",
  "checkbox": "cyO",
  "icon": "cym",
  "creditor-item-container": "cyp",
  "actions-item-container": "cyx",
  "due-date": "cyw",
  "amount": "cyN",
  "align-right": "cyv"
};
