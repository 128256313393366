export default {
  "container": "caP",
  "title-and-trash": "cal",
  "trash": "caX",
  "quantity-amount": "caC",
  "flex-row": "cak",
  "quantity": "caJ",
  "quantityInput": "cas",
  "border-left": "caF",
  "dropdown": "cag",
  "item-row": "caD",
  "unit-price": "caV",
  "price-input": "caL",
  "hidden": "caT",
  "d-none": "caz",
  "dropdown-option": "caa",
  "list-row": "caH",
  "row-title": "caO",
  "row-price": "cam body-2",
  "row-missing-details-container": "cap",
  "row-missing-details-label": "cax body-2",
  "warning-icon": "caw",
  "row-internal-note": "caN",
  "with-internal-note": "cav",
  "dropdown-content": "cao",
  "select-item": "can"
};
