export default {
  "row": "cxb",
  "active": "cxy",
  "animated": "cxU",
  "fadein-item": "cxj",
  "cell": "cxW",
  "cell-content": "cxu",
  "row-sidebar": "cwS",
  "empty": "cwc",
  "cell-amount": "cwq",
  "subtitle": "cwZ caption",
  "hidden": "cwR",
  "cell-approve": "cwQ",
  "quick-actions": "cwe",
  "account-select": "cwB",
  "note": "cwE",
  "cell-content-amount": "cwd body-1",
  "cell-status": "cwI"
};
