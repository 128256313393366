export default {
  "header-cell": "cpQ",
  "empty": "cpe",
  "header-content": "cpB caption-bold",
  "active": "cpE",
  "align-right": "cpd",
  "col-8": "cpI",
  "col-7": "cpt",
  "col-5": "cpM",
  "col-4": "cpP"
};
