export default {
  "row": "cGc",
  "active": "cGq",
  "dot": "cGZ",
  "red": "cGR",
  "green": "cGQ",
  "yellow": "cGe",
  "status": "cGB",
  "align-right": "cGE",
  "animated": "cGd",
  "fadein-item": "cGI",
  "cell": "cGt body-2",
  "cell-content": "cGM",
  "amount": "cGP body-1",
  "subtitle": "cGl caption",
  "no-padding": "cGX"
};
