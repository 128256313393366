export default {
  "iban": "crE",
  "checkbox": "crd",
  "toggle-content": "crI",
  "beneficiary-name": "crt",
  "category": "crM",
  "beneficiary-email": "crP",
  "beneficiary-form": "crl",
  "beneficiary-email-container": "crX"
};
