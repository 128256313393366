/* import __COLOCATED_TEMPLATE__ from './client-information.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { task, timeout } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';
import { alias } from 'macro-decorators';

import CURRENCIES from 'qonto/constants/currencies';
import {
  DEFAULT_PAYMENT_CONDITION,
  DEFAULT_PAYMENT_METHOD,
  ES_FREELANCER_LEGAL_FORM,
  IRPF_ES_DEFAULT_RATE,
} from 'qonto/constants/receivable-invoice';
import { getDefaultClientValues } from 'qonto/models/client-hub';
import type InvoiceSubscriptionModel from 'qonto/models/invoice-subscription';
import type QuoteModel from 'qonto/models/quote';
import type ReceivableInvoiceModel from 'qonto/models/receivable-invoice';
import type ReceivableInvoiceItemModel from 'qonto/models/receivable-invoice/item';
import type ReceivableInvoiceSectionModel from 'qonto/models/receivable-invoice/section';

interface ClientInformationSignature {
  // The arguments accepted by the component
  Args: {
    isFromQuote?: boolean;
    isRecurringInvoice?: boolean;
    isEdit?: boolean;
    isQuote?: boolean;
    isFirstFrenchEinvoice?: boolean;
    document: ReceivableInvoiceModel | QuoteModel | InvoiceSubscriptionModel;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ClientInformationComponent extends Component<ClientInformationSignature> {
  @service declare segment: Services['segment'];
  @service declare store: Services['store'];
  @service declare modals: Services['modals'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];

  // @ts-expect-error
  @alias('args.document.customer') customer;

  @tracked isCustomerInvalid = false;
  @tracked modalDidClose = false;

  constructor(owner: unknown, args: ClientInformationSignature['Args']) {
    super(owner, args);

    this.#validateCustomer();
  }

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);
    // @ts-expect-error
    this.clientFormModal?.close();
  }

  get isFromQuote() {
    return this.args.isFromQuote;
  }

  get isCustomerChangeable() {
    if (this.args.isRecurringInvoice && this.args.isEdit) return false;

    if (this.selectedCustomer) {
      return !this.isFromQuote;
    }

    return true;
  }

  get hasCustomerRequiredError() {
    return this.args.document.errors.has('customer/data/id');
  }

  get hasCustomerInvalidError() {
    // eslint-disable-next-line ember/no-array-prototype-extensions
    let errorsArray = this.args.document.errors.toArray();
    return (
      !this.hasCustomerRequiredError &&
      errorsArray?.some(error => error.attribute.startsWith('customer'))
    );
  }

  get selectedCustomer() {
    return this.customer?.content;
  }

  get customerError() {
    // @ts-expect-error
    if (this.args.customers.length > 0) {
      return this.intl.t(
        'receivable-invoices.invoice-creation.errors.missing-customer.has-customer'
      );
    }
    return this.intl.t('receivable-invoices.invoice-creation.errors.missing-customer.no-customer');
  }

  get isSpanishFreelancer() {
    return (
      this.organizationManager.organization.isSpanish &&
      this.organizationManager.organization.legalForm === ES_FREELANCER_LEGAL_FORM
    );
  }

  // @ts-expect-error
  @action updateCustomer(customer) {
    this.isCustomerInvalid = false;
    this.customer = customer;

    if (customer.currency !== CURRENCIES.default) {
      // @ts-expect-error
      this.args.document.directDebitEnabled = false;
      // @ts-expect-error
      if (this.args.document.payment?.method && this.args.document.payment?.conditions) {
        // @ts-expect-error
        this.args.document.payment.method = DEFAULT_PAYMENT_METHOD;
        // @ts-expect-error
        this.args.document.payment.conditions = DEFAULT_PAYMENT_CONDITION;
      }
    }

    if (customer.constructor.modelName === 'client-hub') {
      this.#validateCustomer();
    }

    if (customer) {
      this.args.document.errors.remove('customer/data/id');

      if (this.hasCustomerInvalidError) {
        // eslint-disable-next-line ember/no-array-prototype-extensions
        this.args.document.errors
          .toArray()
          .map(item => item.attribute)
          .filter(key => key.startsWith('customer'))
          .forEach(key => this.args.document.errors.remove(key));
      }

      this.args.document.currency = customer.currency || CURRENCIES.default;

      let isFrenchEinvoiceQontoToQonto =
        // @ts-expect-error
        Boolean(this.args?.canCreateFrEinvoice && customer?.einvoicing);

      let isFrenchEinvoiceQontoToExternal = Boolean(customer?.get('eInvoicingAddress'));

      let isEinvoice =
        this.organizationManager.organization.isFrench &&
        !this.args.isQuote &&
        (isFrenchEinvoiceQontoToQonto || isFrenchEinvoiceQontoToExternal);

      // @ts-expect-error
      this.args.document.isEinvoice = isEinvoice;

      if (isEinvoice) {
        this.segment.track('invoice-creation_client_added', {
          property: 'einvoicing',
        });
      } else {
        this.segment.track('invoice-creation_client_added');
      }
    }

    let customerBillingCountry = customer?.get('billingAddress.countryCode');

    if (this.isSpanishFreelancer) {
      let withholdingTax;
      if (customerBillingCountry === 'ES') {
        withholdingTax = this.store.createRecord('receivable-invoice/withholding-tax', {
          // @ts-expect-error
          rate: this.args.lastWithholdingTaxRate || IRPF_ES_DEFAULT_RATE,
          reason: '',
          type: '',
        });
      } else {
        withholdingTax = this.store.createRecord('receivable-invoice/withholding-tax', {
          rate: undefined,
          reason: undefined,
          type: undefined,
        });
      }

      this.args.document.withholdingTax = withholdingTax;
    }

    if (
      customerBillingCountry &&
      customerBillingCountry !== this.organizationManager.organization.legalCountry
    ) {
      if (variation('feature--boolean-ar-advanced-customization')) {
        this.args.document.sections.forEach((section: ReceivableInvoiceSectionModel) =>
          section.items.forEach((item: ReceivableInvoiceItemModel) => item.resetVat())
        );
      } else {
        // @ts-expect-error
        this.args.document.items.forEach(item => item.resetVat());
      }
    }

    // @ts-expect-error
    this.args.onCustomerUpdate?.(customer);
  }

  enableTooltip = task(async () => {
    if (!this.modalDidClose) return;

    await timeout(200);
    this.modalDidClose = false;
  });

  @action openClientForm() {
    let client = this.store.createRecord('client-hub', getDefaultClientValues(this));
    let settings = this.store.peekRecord(
      'receivable-invoices-settings',
      this.organizationManager.organization.id
    );

    this.segment.track('invoice-creation_client-creation_started');

    if (!this.args.isQuote) {
      this.segment.track('recurring-invoice_client-creation_started', {
        origin: 'recurringInvoiceForm',
      });
    }

    // @ts-expect-error
    this.clientFormModal = this.modals.open(
      'receivable-invoices/client-form',
      {
        onClientSave: this.updateCustomer,
        isFullScreenModal: true,
        client,
        onClose: () => {
          this.modalDidClose = true;
        },
        savedAddressName: settings?.clientAddressName,
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
          allowOutsideClick: true,
        },
      }
    );
  }

  @action openEditClientForm() {
    let clientId = this.args.document.customer.get('id');
    let client = this.store.peekRecord('client-hub', clientId);

    // @ts-expect-error
    if (!client.currency) {
      // @ts-expect-error
      client.currency = CURRENCIES.default;
    }

    this.segment.track('invoice-creation_client-options_edit');

    // @ts-expect-error
    this.clientFormModal = this.modals.open(
      'receivable-invoices/client-form',
      {
        onClientSave: this.updateCustomer,
        isEdit: true,
        isFullScreenModal: true,
        showDeleteButton: !this.isFromQuote,
        client,
        onClose: () => {
          this.modalDidClose = true;
        },
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
          initialFocus: false,
        },
      }
    );
  }

  #validateCustomer() {
    if (this.customer.id) {
      let customerRecord = this.store.peekRecord('client-hub', this.customer.id);
      customerRecord?.validate();
      // @ts-expect-error
      this.isCustomerInvalid = customerRecord.validations.isInvalid;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Form::ClientInformation': typeof ClientInformationComponent;
  }
}
