export default {
  "header": "cfJ",
  "header-type": "cfs",
  "close": "cfF",
  "close-icon": "cfg",
  "header-infos": "cfD",
  "title": "cfV body-1",
  "declined": "cfL",
  "canceled": "cfT",
  "approved": "cfz",
  "date": "cfa body-2",
  "container": "cfH",
  "picto": "cfO",
  "request": "cfm",
  "picto-status": "cfp",
  "check": "cfx",
  "stop": "cfw",
  "warning": "cfN",
  "general": "cfv",
  "amount": "cfo",
  "counterparty": "cfn body-2",
  "initiator": "cfi",
  "initiator-avatar": "cfA",
  "avatar-image": "cfY",
  "initiator-id": "cfh",
  "name": "cff body-2",
  "role": "cfK caption-bold",
  "infobox": "cfG",
  "account-infobox": "cfr cfG",
  "beneficiary-warning": "cfb caption-bold",
  "dates-wrapper": "cfy",
  "dates": "cfU",
  "recurrence": "cfj caption-bold",
  "arrow": "cfW",
  "notify-checkbox": "cfu"
};
