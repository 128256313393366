export default {
  "subtitle": "cit",
  "green-text": "ciM",
  "balance-subtitle": "ciP",
  "options": "cil",
  "shadow": "ciX",
  "menu-item": "ciC body-2",
  "close-account": "cik",
  "footer": "ciJ",
  "progress": "cis",
  "progress-bar": "ciF",
  "progress-bar-text": "cig",
  "progress-active": "ciD"
};
