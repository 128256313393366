export default {
  "wrapper": "cxg",
  "tabs": "cxD",
  "tabs-nav": "cxV",
  "tabs-panel-wrapper": "cxL",
  "list-head": "cxT",
  "list": "cxz",
  "footer": "cxa",
  "reward-amount": "cxH",
  "earned": "cxO",
  "pending": "cxm",
  "empty-state-wrapper": "cxp",
  "empty-state": "cxx",
  "illustration": "cxw",
  "error-wrapper": "cxN",
  "error-illustration": "cxv",
  "error-description": "cxo",
  "spinner": "cxn",
  "total-earned": "cxi"
};
