export default {
  "qonto-pilot-modal": "cVW",
  "content": "cVu",
  "header": "cLS",
  "transition-state-container": "cLc",
  "lottie": "cLq",
  "prompt-input": "cLZ",
  "buttons": "cLR",
  "footer": "cLQ caption",
  "divider": "cLe",
  "illustration": "cLB",
  "icon": "cLE",
  "quick-actions-container": "cLd",
  "action": "cLI body-2",
  "action-title": "cLt",
  "disabled": "cLM",
  "action-tag": "cLP caption-bold",
  "hotkey": "cLl body-1",
  "footer-action": "cLX",
  "grow": "cLC",
  "footer-feedback-link": "cLk",
  "buttons-container": "cLJ",
  "transfer-details-container": "cLs",
  "error-container": "cLF",
  "option-iban": "cLg",
  "actions": "cLD",
  "title": "cLV",
  "group-title": "cLL caption-bold",
  "beta-badge": "cLT",
  "hotkeys": "cLz",
  "supplier-invoices-dropzone": "cLa",
  "success-icon": "cLH",
  "error-icon": "cLO",
  "file-items": "cLm",
  "file-item": "cLp",
  "uploader-file": "cLx",
  "transactions-table": "cLw",
  "transaction": "cLN",
  "prompt-example": "cLv"
};
