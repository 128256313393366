export default {
  "container": "cmb",
  "share-panel": "cmy",
  "email-margin": "cmU",
  "message-margin": "cmj",
  "esignature-container": "cmW",
  "esignature-teaser-title": "cmu",
  "esignature-teaser-badge": "cpS",
  "esignature-teaser-body": "cpc",
  "button-container-footer": "cpq",
  "attachments": "cpZ",
  "attachment-file": "cpR"
};
