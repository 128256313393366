export default {
  "referral-invite": "cxE",
  "referral": "cxd",
  "illustration": "cxI",
  "steps": "cxt",
  "step": "cxM",
  "share-referral": "cxP",
  "choice": "cxl",
  "line": "cxX",
  "email": "cxC",
  "email-input": "cxk",
  "input-field--error": "cxJ",
  "error-message": "cxs",
  "external-link-icon": "cxF"
};
