export default {
  "header": "cwv",
  "header-type": "cwo",
  "close": "cwn",
  "close-icon": "cwi",
  "header-infos": "cwA",
  "title": "cwY body-1",
  "declined": "cwh",
  "canceled": "cwf",
  "approved": "cwK",
  "pending": "cwG",
  "date": "cwr body-2",
  "mileage-icon": "cwb",
  "distance-subtitle": "cwy body-2",
  "link-icon": "cwU",
  "container": "cwj",
  "picto": "cwW",
  "general": "cwu",
  "amount": "cNS",
  "calculation-container": "cNc",
  "counterparty": "cNq body-2",
  "infobox": "cNZ"
};
