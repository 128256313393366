export default {
  "sections": "crL",
  "actions": "crT",
  "search-input": "crz",
  "no-upload": "cra",
  "new-link": "crH body-2",
  "between-links": "crO",
  "action-bar": "crm",
  "empty-state": "crp"
};
