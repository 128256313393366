export default {
  "row": "cAz",
  "empty": "cAa",
  "active": "cAH",
  "animated": "cAO",
  "fadein-item": "cAm",
  "cell": "cAp body-2",
  "cell-content": "cAx",
  "cell-amount": "cAw",
  "cell-content-amount": "cAN body-1"
};
