export default {
  "mileage-validation": "cob",
  "actions": "coy",
  "action-btn": "coU",
  "action-btn-right": "coj",
  "request-mileage-validation": "coW",
  "account-selector": "cou",
  "has-error": "cnS",
  "funds-disclaimer": "cnc",
  "account-options": "cnq",
  "account-balance": "cnZ",
  "warnings": "cnR"
};
