export default {
  "attachment-viewer": "cYE",
  "hide-sidebar": "cYd",
  "sidebar": "cYI",
  "header": "cYt",
  "header-top": "cYM",
  "header-icon": "cYP",
  "header-amount": "cYl",
  "infobox": "cYX",
  "vat": "cYC",
  "validation": "cYk"
};
