export default {
  "card-details-container": "cAG",
  "title": "cAr caption-bold mb-16",
  "section": "cAb",
  "divider": "cAy",
  "top-divider": "cAU",
  "content": "cAj body-2",
  "description": "cAW",
  "justify-end": "cAu",
  "icon": "cYS ml-8"
};
