export default {
  "numbering-container": "czu",
  "flex-row": "caS",
  "automatic-number-label": "cac",
  "icon-tooltip": "caq",
  "fix-width": "caZ",
  "dates-container": "caR",
  "with-performance-date": "caQ",
  "date-picker": "cae",
  "label": "caB",
  "header-text-field-container": "caE",
  "mt-16": "cad",
  "header-text-field": "caI",
  "header-text-field-close-button": "cat",
  "deposit-toggle-container": "caM"
};
