export default {
  "invoice-sidebar": "cHP",
  "body": "cHl",
  "box": "cHX",
  "box-header": "cHC",
  "footer": "cHk",
  "footer-button": "cHJ",
  "footer-button-tooltip": "cHs",
  "reduced-fontsize": "cHF",
  "sidebar-box": "cHg",
  "border-top": "cHD",
  "row": "cHV",
  "status-container": "cHL",
  "due-date-container": "cHT",
  "greyed-out": "cHz",
  "struck-through": "cHa",
  "overdue": "cHH",
  "primary-actions": "cHO cHg",
  "primary-action": "cHm",
  "danger-action": "cHp",
  "button-icon": "cHx",
  "button-label": "cHw body-2",
  "related-credit-note-title": "cHN caption-bold",
  "paid-at-placeholder": "cHv",
  "collapsible-timeline": "cHo",
  "timeline-status-label": "cHn",
  "related-documents": "cHi"
};
