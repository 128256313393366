export default {
  "wrapper": "crU",
  "header": "crj mb-48",
  "form-title": "crW title-2 mb-16",
  "form-subtitle": "cru body-2",
  "form": "cbS",
  "section": "cbc mb-48",
  "section-title": "cbq title-3 mb-16",
  "form-footer": "cbZ",
  "footer-btn": "cbR",
  "transfer-form": "cbQ"
};
