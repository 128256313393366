export default {
  "request-expense-report-validation": "chR",
  "account-selector": "chQ",
  "has-error": "che",
  "actions": "chB",
  "action-btn": "chE",
  "action-btn-success": "chd",
  "warnings": "chI",
  "top-border": "cht",
  "account-selector-subtitle": "chM",
  "action-btn-right": "chP",
  "funds-disclaimer": "chl",
  "account-options": "chX",
  "account-balance": "chC"
};
