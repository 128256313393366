export default {
  "section-container": "cwL",
  "disclaimer-section": "cwT",
  "trip-title": "cwz caption-bold mb-16",
  "google-link-container": "cwa",
  "link-icon": "cwH",
  "trip-details": "cwO body-2",
  "trip-details-icon": "cwm",
  "trip-details-title": "cwp",
  "subtitle": "cwx caption-bold mb-16",
  "disclaimer": "cww",
  "visualizer": "cwN"
};
