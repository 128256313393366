export default {
  "header-cell": "cKr",
  "header-content": "cKb caption-bold",
  "active": "cKy",
  "empty": "cKU",
  "request-type": "cKj",
  "status": "cKW",
  "note": "cKu",
  "amount": "cGS"
};
