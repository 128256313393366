export default {
  "payment-details": "cai",
  "label-payment-conditions": "caA",
  "dropdown-content": "caY",
  "dropdown-option": "cah",
  "activate-sdd-container": "caf",
  "activate-sdd-header": "caK",
  "selector-toggle-sdd": "caG",
  "selector-toggle-sdd-subtitle": "car",
  "selector-toggle-content": "cab",
  "selector-toggle": "cay",
  "new-badge": "caU",
  "new-badge-activation": "caj",
  "mt-16": "caW",
  "sdd-mandate-select-loading": "cau"
};
