export default {
  "container": "cOv",
  "main": "cOo",
  "preview": "cOn",
  "form": "cOi",
  "scrollable": "cOA",
  "header": "cOY title-1",
  "form-sections": "cOh",
  "form-section": "cOf",
  "footer": "cOK",
  "preview-header": "cOG",
  "centered": "cOr",
  "preview-content-wrapper": "cOb",
  "preview-content": "cOy",
  "payment-link-preview": "cOU"
};
