export default {
  "selector": "czf",
  "pension-selector-content": "czK",
  "withholding-tax-selector-content": "czG",
  "withholding-tax-selector-content-row": "czr",
  "power-select-field": "czb",
  "rate-field": "czy",
  "stamp-revenue-field": "czU",
  "dropdown-option": "czj",
  "dropdown-content": "czW"
};
