export default {
  "wrapper": "ciW",
  "request-header": "ciu",
  "request": "cAS",
  "scheduled": "cAc",
  "scheduled-label": "cAq",
  "note": "cAZ",
  "note-label": "cAR",
  "note-label-danger": "cAQ",
  "note-content": "cAe",
  "status": "cAB",
  "transfer-amount": "cAE"
};
