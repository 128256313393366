export default {
  "credit-note-sidebar": "cTt",
  "body": "cTM",
  "box": "cTP",
  "box-header": "cTl",
  "footer": "cTX",
  "footer-button": "cTC",
  "sidebar-box": "cTk",
  "border-top": "cTJ",
  "row": "cTs",
  "related-invoice-title": "cTF caption-bold",
  "einvoice-rejected": "cTg",
  "related-documents": "cTD"
};
