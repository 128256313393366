export default {
  "row": "cGC",
  "active": "cGk",
  "animated": "cGJ",
  "fadein-item": "cGs",
  "cell": "cGF body-2",
  "cell-content": "cGg",
  "subtitle": "cGD caption",
  "no-padding": "cGV",
  "note": "cGL",
  "cell-amount": "cGT",
  "cell-content-amount": "cGz body-1"
};
