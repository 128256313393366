export default {
  "section-container": "cod",
  "disclaimer-section": "coI",
  "trip-title": "cot caption-bold mb-16",
  "google-link-container": "coM",
  "link-icon": "coP",
  "trip-details": "col body-2",
  "trip-details-icon": "coX",
  "trip-details-title": "coC",
  "subtitle": "cok caption-bold mb-16",
  "disclaimer": "coJ",
  "visualizer": "cos"
};
