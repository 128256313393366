export default {
  "invoice-sidebar": "cHj",
  "body": "cHW",
  "box": "cHu",
  "box-header": "cOS",
  "footer": "cOc",
  "footer-button": "cOq",
  "sidebar-box": "cOZ",
  "border-top": "cOR",
  "row": "cOQ",
  "status-container": "cOe",
  "due-date-container": "cOB",
  "mark-as-btn": "cOE",
  "primary-actions": "cOd cOZ",
  "primary-action": "cOI body-1",
  "button-icon": "cOt"
};
