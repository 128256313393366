export default {
  "header-cell": "cKm",
  "header-content": "cKp caption-bold",
  "active": "cKx",
  "empty": "cKw",
  "align-right": "cKN",
  "request-type": "cKv",
  "requester": "cKo",
  "amount": "cKn"
};
