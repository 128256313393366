export default {
  "sidebar": "cHA",
  "body": "cHY",
  "form": "cHh",
  "form-section": "cHf",
  "form-title": "cHK title2",
  "row": "cHG",
  "payment-details": "cHr",
  "col": "cHb",
  "footer": "cHy",
  "footer-button": "cHU"
};
