export default {
  "form": "cOj",
  "container": "cOW",
  "section": "cOu",
  "footer": "cmS",
  "attachment": "cmc",
  "attachment-title": "cmq",
  "attachment-uploader-disabled": "cmZ",
  "attachment-overlay": "cmR",
  "attachment-overlay-hidden": "cmQ"
};
