export default {
  "row": "cGa",
  "active": "cGH",
  "dot": "cGO",
  "red": "cGm",
  "green": "cGp",
  "yellow": "cGx",
  "status": "cGw",
  "align-right": "cGN",
  "animated": "cGv",
  "fadein-item": "cGo",
  "cell": "cGn body-2",
  "cell-content": "cGi",
  "amount": "cGA body-2",
  "subtitle": "cGY caption",
  "padding-left": "cGh",
  "padding-right": "cGf",
  "note": "cGK"
};
