export default {
  "row": "cpC",
  "cell": "cpk",
  "greyed-out": "cpJ",
  "struck-through": "cps",
  "flex-cell": "cpF",
  "details-container": "cpg",
  "overdue": "cpD",
  "align-right": "cpV",
  "customer-name-container": "cpL",
  "icon": "cpT",
  "warning": "cpz",
  "with-separator": "cpa",
  "align-center": "cpH"
};
