export default {
  "referral-invite-new": "cpp",
  "referral": "cpx",
  "center": "cpw",
  "illustration": "cpN",
  "share-referral": "cpv",
  "choice": "cpo",
  "line": "cpn",
  "email": "cpi",
  "email-input": "cpA",
  "input-field--error": "cpY",
  "error-message": "cph",
  "external-link-icon": "cpf"
};
