export default {
  "modal": "cme",
  "action-container": "cmB",
  "similar-label": "cmE",
  "info-icon": "cmd",
  "container": "cmI",
  "subtitle": "cmt caption-bold",
  "error": "cmM",
  "item-container": "cmP"
};
