export default {
  "header": "coF",
  "header-type": "cog",
  "close": "coD",
  "close-icon": "coV",
  "header-infos": "coL",
  "title": "coT body-1",
  "declined": "coz",
  "canceled": "coa",
  "approved": "coH",
  "pending": "coO",
  "date": "com body-2",
  "mileage-icon": "cop",
  "distance-subtitle": "cox body-2",
  "link-icon": "cow",
  "container": "coN",
  "picto": "cov",
  "general": "coo",
  "amount": "con",
  "calculation-container": "coi",
  "counterparty": "coA body-2",
  "infobox": "coY"
};
