export default {
  "row": "cnF",
  "active": "cng",
  "dot": "cnD",
  "red": "cnV",
  "green": "cnL",
  "yellow": "cnT",
  "status": "cnz",
  "align-right": "cna",
  "animated": "cnH",
  "fadein-item": "cnO",
  "cell": "cnm body-2",
  "cell-content": "cnp",
  "amount": "cnx body-2",
  "subtitle": "cnw caption",
  "padding-left": "cnN",
  "padding-right": "cnv",
  "note": "cno"
};
