export default {
  "modal-wrapper": "cmL",
  "spinner-container": "cmT",
  "spinner": "cmz",
  "container": "cma",
  "tabs-item": "cmH",
  "form-container": "cmO",
  "fieldset": "cmm",
  "preview-container": "cmp",
  "switch-container": "cmx",
  "pdf-preview-container": "cmw",
  "form-content": "cmN",
  "button-container-footer": "cmv"
};
