export default {
  "card": "cTn",
  "error": "cTi",
  "customer-info": "cTA",
  "edit-customer-tooltip": "cTY",
  "title": "cTh",
  "flex-row": "cTf",
  "dot-separator": "cTK",
  "flex-span": "cTG",
  "faq-link": "cTr",
  "tooltip-wrapper": "cTb",
  "badge-union": "cTy"
};
