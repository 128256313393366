export default {
  "header-cell": "cnB",
  "empty": "cnE",
  "header-content": "cnd caption-bold",
  "active": "cnI",
  "header-type": "cnt",
  "header-reason": "cnM",
  "header-status": "cnP",
  "header-amount": "cnl",
  "row-sidebar": "cnX",
  "hidden": "cnC",
  "animated": "cnk",
  "fadein-item": "cnJ",
  "animated-cell": "cns"
};
