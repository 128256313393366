export default {
  "cell-content": "cAJ",
  "icon": "cAs",
  "status_check-m": "cAF",
  "description": "cAg body-1",
  "from": "cAD body-2",
  "from--abort": "cAV",
  "description--abort": "cAL",
  "title": "cAT body-2"
};
